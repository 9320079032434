.about {
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    background-color: black;
    box-shadow: 0px 0 50px  rgba(56, 56, 56, 0.8);
    border-top-left-radius: 10% ;
    border-top-right-radius: 10%; 

  

}
.about_items{
    margin: 0% 15%;
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat';

}

.my_pic{
    display: flex;
    justify-content: center;
}

.my_pic img{
    width: 25%;
    height: auto;
    border-radius: 55%;
    padding: 15px 10px 15px 10px;   
    /* -webkit-transform: rotate(90deg);  */
    /* margin-top: 30px; */
    margin: 50px 0 10px 0;
    background-color: hsl(34, 53%, 82%);
background-image: repeating-linear-gradient(45deg, transparent 5px, hsla(197, 62%, 11%, 0.5) 5px, hsla(197, 62%, 11%, 0.5) 10px,
  hsla(5, 53%, 63%, 0) 10px, hsla(5, 53%, 63%, 0) 35px, hsla(5, 53%, 63%, 0.5) 35px, hsla(5, 53%, 63%, 0.5) 40px,
  hsla(197, 62%, 11%, 0.5) 40px, hsla(197, 62%, 11%, 0.5) 50px, hsla(197, 62%, 11%, 0) 50px, hsla(197, 62%, 11%, 0) 60px,
  hsla(5, 53%, 63%, 0.5) 60px, hsla(5, 53%, 63%, 0.5) 70px, hsla(35, 91%, 65%, 0.5) 70px, hsla(35, 91%, 65%, 0.5) 80px,
  hsla(35, 91%, 65%, 0) 80px, hsla(35, 91%, 65%, 0) 90px, hsla(5, 53%, 63%, 0.5) 90px, hsla(5, 53%, 63%, 0.5) 110px,
  hsla(5, 53%, 63%, 0) 110px, hsla(5, 53%, 63%, 0) 120px, hsla(197, 62%, 11%, 0.5) 120px, hsla(197, 62%, 11%, 0.5) 140px
  ),
repeating-linear-gradient(135deg, transparent 5px, hsla(197, 62%, 11%, 0.5) 5px, hsla(197, 62%, 11%, 0.5) 10px,
  hsla(5, 53%, 63%, 0) 10px, hsla(5, 53%, 63%, 0) 35px, hsla(5, 53%, 63%, 0.5) 35px, hsla(5, 53%, 63%, 0.5) 40px,
  hsla(197, 62%, 11%, 0.5) 40px, hsla(197, 62%, 11%, 0.5) 50px, hsla(197, 62%, 11%, 0) 50px, hsla(197, 62%, 11%, 0) 60px,
  hsla(5, 53%, 63%, 0.5) 60px, hsla(5, 53%, 63%, 0.5) 70px, hsla(35, 91%, 65%, 0.5) 70px, hsla(35, 91%, 65%, 0.5) 80px,
  hsla(35, 91%, 65%, 0) 80px, hsla(35, 91%, 65%, 0) 90px, hsla(5, 53%, 63%, 0.5) 90px, hsla(5, 53%, 63%, 0.5) 110px,
  hsla(5, 53%, 63%, 0) 110px, hsla(5, 53%, 63%, 0) 140px, hsla(197, 62%, 11%, 0.5) 140px, hsla(197, 62%, 11%, 0.5) 160px
);
}
.introduction h3{
color: white;
margin: 1rem 0;

}

.personal_intro p{
    line-height: 1.5rem;
    margin: 1.5rem 0;
   
}
.personal_intro p span{
    color: saddlebrown;
}

.technologies h3{
    color: white;
    margin-bottom: 1.5rem;
    
}
.technologies h4{
    color: white;
    margin: 1.8rem 0;

}

.technologies img{
    width: 80%;
    height: auto;
    padding: 10px;
    border-radius: 5%;
}
.technologies img:hover{
    opacity: 0.6;
}

.languages{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.list_of_languages img{
    margin-right: 2%;
    margin-top: 10%;
}

.tooltip{
  position: relative;
  display: inline-block;
}

.tooltip .tooltip_text {
    visibility: hidden;
    width: 80%;
    background-color: rgb(73, 33, 4);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 2% 0;
    bottom: 100%;
    left: 50%;
    margin-left: -40%;
    margin-bottom: 0.2rem;
    position: absolute;
    z-index: 1;
  }
  
  .tooltip:hover .tooltip_text {
    visibility: visible;
  }


.java img{
 background-color: rgb(241, 103, 103);

}

.python img{
    
    background-color: rgb(125, 206, 243);
   
}

.js img{
   
    background-color: rgb(243, 178, 82);
}

.htm img{
    background-color: rgba(230, 140, 5, 0.959);
}


.csss img{
    background-color: rgb(22, 163, 245);
}

.sql img{ 
    background-color: blanchedalmond;

}
.nodejs img{
    background-color: blanchedalmond;
}

.php img{
    background-color: seagreen;
}


.reac_t img{
    background-color: rgb(38, 195, 243);
    
}

.react_native img{
    background-color: rgb(130, 218, 245);
      
}


/* CSS of list of tools */
.tools{
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     margin-bottom: 2rem;
}

.list_of_tools button{
    width: 18%;
    height: auto;
    margin-right: 2%;
    border-radius: 1.5rem;
    box-sizing: border-box;
    padding: 8px;
    color: white;
    background-color: rgb(29, 28, 28);   

}
.list_of_tools button:hover{
    opacity: 0.6;

}

@media screen and (max-width: 600px){
    .about_items{
        margin: 0% 5%;
    }
    .list_of_tools{
        display: flex;
        flex-direction: column;

    }
    .list_of_tools button{
        margin-bottom: 5%;
        width: 100%;
    }
    .technologies img{
        width: 100%;
        height: auto;
       
    }

    .my_pic img{
        width: 100px;
        height: 100px;
        
          
    }
}

