
.open_preview{
  display: none;
}

.close_preview{
  display: block;
}

.topics{
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat';
  margin: 5% 15%;
 
}


.topics h1{
    font-weight: bold;
    margin-bottom: 5%;
}

.project{
  display: flex;
  flex-direction: row;
  align-items: center;  
  margin-bottom: 2%; 

}

.project h3{
  margin-left: 2%;
  color:#84baea;
  font-size: 25px;
  }

.description_of_project{
  line-height: 1.7rem;
  display: flex;
  flex-direction: column;
}
.description_of_project span{
  color: chocolate;
  font-size: 18px;
}


.pre_visit_btn{
  display: flex;
  flex-direction: row; 
  justify-content: center;
  margin: 3% 0;
  font-size: 16px;
  margin-bottom: 3rem;

}
.prev_btns_spacer{
  flex: 7;
}

.preview{
  flex: 2;
  color: rgb(253, 253, 253);
  background-color: #1389f1;
  border-radius: 2rem;
  margin-right: 2%;
  height: auto;
  
  
}

.visit_site{
  flex: 2;
  width: 15%;
  height: auto;
  border: 2px solid;
  border-radius: 2rem;
  border-color: #1389f1; 
  background-color: black;
  color: white; 
}
a{
  text-decoration: none;
}

.visit_site span{
  content: "\261E";
  font-size: 24px;
  margin-right: 1rem;
  padding-left: 0.8rem;
  
}



@media screen and (max-width: 600px){

  .topics{
    margin: 5% 2%;
    
  }  

  .pre_visit_btn{
    flex-direction: column;
    padding: 1.5rem 0;
  }
  .preview {
    width: 90%;
    padding: 1rem;
    margin-bottom: 1rem;
    padding-left: 1.5rem;
  }
  /* .preview .field-icon{
    margin-right: .1rem;
} */
  .visit_site{
    width: 90%;
    padding-bottom: .4rem;
    
  }
  .visit_site span{
    margin-right: 3rem;
    color: red;
  }

  
 
}

 
@media screen and (min-width: 600px) and (max-width: 768px){

  .topics{
    margin: 5% 2%;
    
  }

  .topics h1{
    font-size: 48px;
    
}
.project h3{
  font-size: 34px;
}
.description_of_project{
  font-size: 22px;
}
  .pre_visit_btn{
    flex-direction: row;
    margin-top: 1rem;
  }
  .prev_btns_spacer{
    flex: 4;
  }
  .preview {
   
    flex: 3;
   
  }

  .visit_site{
   
    flex: 3;
  
  }
 
} 


@media  screen and (min-width: 769px) and (max-width: 1200px){
  .topics{
    margin: 5% 10%;
   
  }
  .prev_btns_spacer{
    flex: 3;
  }

}
