*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  min-height: 100vh;
  color: white;
  
  /* position: relative; */
  padding-bottom: 58px;
  /* background: linear-gradient(black, white); */
  /* background-color: black; */
  /* background: radial-gradient(circle, black, #2D0A01 ); */
  background:
    radial-gradient(black 15%, transparent 16%) 0 0,
    radial-gradient(black 15%, transparent 16%) 8px 8px,
    radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 0 1px,
    radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 8px 9px;
    /* background-color:#282828; */
    background-color: black;
    background-size:16px 16px;

}
.app{
  display: flex;
  flex-direction: column;
  /* margin-left: 2.5rem;
  margin-right: 2.5rem; */
  margin: 0 auto;
  width: 90vw;
  padding: 30px 20px;

}
