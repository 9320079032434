.modal{
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    /* background-color: firebrick; */
}

.modal-main {
    position:fixed;
    background: white;
    width: 300px;
    height: 300px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
  }
  .display-block {
    display: block;
  }
  .display-none {
    display: none;
  }  
  
  .txt_color h1{
        color: rgb(247, 243, 243);
        font-family: 'Baloo Da 2', cursive;
        letter-spacing: 0.1rem;
        font-weight: bolder;
        font-size: xx-large;
        margin-top: 2rem;
  }
  .txt_color h1:hover{
      color: rgb(177, 173, 173);
  }

  .txt_color p{
      margin-left: 45%;
      padding-top: 2rem;
      color: rgb(20, 58, 70);
  }

  .txt_color img{
    width: 60px;
    height: auto;
    border-radius: 5%;
    margin-left: -10%;
   
  }

  .my_details{
    margin-left: -45%;
  }

  @media screen and (max-width: 600px){
    .txt_color h1{
      font-size: 22px;
    }

  }