/* CSS for home page */
.home{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.home h3{
    color: #B48903;
}

.my_name{
    font-family: 'Baloo Da 2', cursive;
    letter-spacing: 0.1rem;
    font-weight: bolder;
    font-size: xx-large;
    margin-top: 2rem;
}
h4{
    font-style: italic;
}
.skills_cv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20%;
}
.back_end{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px;
    margin-bottom: 1rem;
    
    
}
.back_end h3{
    margin-left: 1rem;
    font-family: 'Baloo Da 2', cursive;
    /* padding: 3px; */

}

.skills h4{
    font-family: 'Baloo Da 2', cursive;
    margin-bottom: 2rem;
}
.btn_cv_download{
    border: 2px solid black;
    background-color: white;
    color: black;
    padding: 14px 28px;
    font-size: 16px;
    cursor: pointer;
    margin-left: 30rem;
    padding-top: 10px;
}
.btn_cv_download:hover{
    
    background: linear-gradient(black, white, black);
}


blockquote {
    margin: 0;
}

blockquote p {
    padding: 15px;
    background: #333;
    border-radius: 5px;
}

blockquote p::before {
    content: '\201C';
}

blockquote p::after {
    content: '\201D';
}

/* CSS for Projects Page */

@media screen and (max-width:600px){
    .skills_cv{
        flex-direction: column;
      
    }
    .btn_cv_download{
        margin-left: 0.1rem;
        
    }
    .cv footer{
        margin-bottom: 2rem;
    }
    .qt p{
        margin-bottom: 2rem;
    }
}

@media screen and (min-width: 600px) and (max-width: 768px){
    .skills_cv{
        flex-direction: column;
        align-items: center;
        justify-content: center;
      
    }

    blockquote p {
        width: 50%;
        margin-left: 10%;
    }
    .btn_cv_download{
        margin-left: 20%;
        margin-bottom: 20%;
    }

    .cv footer{
        margin-left: 10%;
        margin-top: 2%;
        margin-bottom: 3%;
    }
    .my_name {
        text-align: center;
        font-size: 58px;
    }
}

@media  screen and (min-width: 768px){
    
    .btn_cv_download{
        margin-left: 50%;
        margin-bottom: 20%;
    }
    .cv footer{
        margin-bottom: 3%;
    }
}