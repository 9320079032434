.tool_bar ul{
    display: flex;
    flex-direction: row;
}

.home{
    flex: .5;
   
}


.tool_bar .home a{
   font-size: 24px;
   position: relative;
   animation: homemove 5s infinite;
}
@keyframes homemove{
    0% {left: 10%;}
    50% {color: magenta;}
    100% {left: 0%}

}
.tool_bar .home .firsthr{
    color: seagreen;
    margin-bottom: 2px;
    transition: ease-out 3s;
}
.tool_bar .home:hover .firsthr{
    width: 20px;
}
.tool_bar .home .secondhr{
    color: cornflowerblue;
}
.tool_bar .home:hover .secondhr{
    width: 40px;
}
.projects_about{
    flex: 1;
    text-align: right;
}
ul{
    justify-content: space-between;
    list-style: none;
}
.tool_bar a{
    text-decoration: none;
    padding: 10px;
    color: white;

}
.tool_bar a:hover{
    color: saddlebrown;
    animation: homemove 5s step-end;
    
    
}
.tool_bar a.active{
    color: rgb(4, 204, 240);
}


@media only screen and (max-width: 600px){
   
    .projects_about{
        margin-top: 1.5rem;
    }
}
@media only screen and (min-width: 600px){
    .projects_about{
        margin-top: 1.5rem;
       
    }
}