.footer{
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 5px;
  background-image: linear-gradient(#ededed, #fff);
    background: -webkit-gradient(linear, center top, center bottom, from(#353434), to(rgb(43, 42, 42)));
    box-shadow: inset 0px 0px 1px 1px rgba(95, 94, 94, 0.1);

}
.footer ul{
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.footer_items a{
    text-decoration: none;
    font-size: 21px;
    font-family: 'Baloo Da 2', cursive;
    color: white;
    padding: 10px;
    
}
.foot_spacer{
  flex: 1;
}

@media screen and (max-width: 600px){
  .footer_items a{
    font-size: 16px;
    padding: 5px;
  }
}