.back_end .card{
    border: 4px solid chocolate;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    padding: .2rem;
}


.project .card{
    border: 4px solid #84baea;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    text-align: center;
    padding: .3rem;
}

@media only screen and (max-width: 600px){
    .back_end .card{
        border-radius: 50%;
        width: 30px;
        height: 30px;
        padding: .2rem;
    }

    .project .card{
        border-radius: 50%;
        width: 30px;
        height: 30px;
        padding: .2rem;
    }
}