.contact{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.email_call{
    letter-spacing: 0.1rem;
    font-size: 1rem;
    font-family: 'Baloo 2 Da', cursive;
    font-style: italic; 
    
}
.email{
    margin-right: 1rem;  
    color: white;
   
}

.call{
    margin-left: 1rem;
    color: white;
}
.linkedin{
    color: rgb(41, 157, 235);
    font-size: 40px;
    margin-right: .5rem;
    letter-spacing: 0.1rem;

}
.twitter{
    font-size: 40px;
    margin-left: .5rem;
    letter-spacing: 0.1rem;
    color: rgb(41, 157, 235);
}


@media only screen and (max-width: 600px){
    .contact{
        display: none;
        
    }
}

@media only screen and (min-width: 600px){
    .contact{
        display: none;
    }
}


/* media queries for larger screens e.g laptops */

@media only screen and (min-width: 768px){
    .contact{
        display: flex;
    }
}

